import {Modal} from "@mui/material";
import {Save} from "Components/Buttons/Save";
import {Adult} from "Components/Sections/Adult";
import {Children} from "Components/Sections/Children";
import {SwitchMemberType} from "Components/Switch/SwitchMemberType";
import {memo, useCallback, useRef, useState} from "react";
import {ReactSVG} from "react-svg";
import {Link} from "react-router-dom";

export const BalanceModal = memo(function CreateMemberComponent({
                                                                  open,
                                                                  close,
                                                                }: {
  open: boolean;
  close: () => void;
}) {
  const containerRef = useRef(null);
  const [isInternetBank, setIsInternetBank] = useState(true);

  const handleIsAdult = useCallback((state: boolean) => {
    setIsInternetBank(state);
  }, []);

  return (
      <>
        <div ref={containerRef}></div>
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            container={containerRef.current}
            className="flex items-center sm:items-end sm:h-full z-[2000]"
        >
          <div
              className="flex sm:rounded-t-[12px] sm:m-0 flex-col w-[492px] sm:h-auto sm:w-full sm:bottom-0 sm:max-h-[100%] sm:rounded-none overflow-y-auto bg-[#fff] m-auto rounded-[12px] p-[32px] sm:p-4">
            <div className="flex justify-between items-center py-[6px] mb-[15px]">
              <h2 className="font-bold text-[20px] text-blackPrimary-100">
                ბალანსის შევსება
              </h2>
              <div
                  onClick={close}
                  className="sm:hidden cursor-pointer w-[44px] h-[44px] bg-gray-30 sm:bg-gray-100 sm:rounded-[8px] rounded-[50%] flex items-center justify-center"
              >
                <ReactSVG src="/images/icons/close.svg"/>
              </div>
            </div>
            <div>
              <SwitchMemberType
                  state={isInternetBank}
                  setState={(state: boolean) => handleIsAdult(state)}
                  values={{
                    name1: "ინტერნეტ ბანკი",
                    name2: "გადახდის აპარატი",
                  }}
              />
            </div>

            <div className="mb-[20px]">
              {isInternetBank ? (
                  <Link to={process.env.REACT_APP_TBC_PAY || ""} target={"_blank"}>
                    <div
                        className="cursor-pointer h-[80px] text-rg font-demi text-blackPrimary-100 bg-gray-30 flex items-center px-[24px] gap-[12px] rounded-[12px] mt-[20px]">
                      <img src="/images/icons/tbc.svg" alt={"tbc"}/>
                      თიბისი ბანკი
                    </div>
                  </Link>
              ) : (
                  <div className="text-rg sm:text-sm font-demi text-blackPrimary-100 leading-[21px] mt-[20px]">
                    ბალანსის შევსება შესაძლებელია თიბისი ბანკის ჩასარიცხი
                    აპარატით.სერვისების ჩამონათვალში აირჩიე "Vitaapp", შეიყვანე
                    მომხმარებლის ID და შეიტანე სასურველი ოდენობის თანხა
                  </div>
              )}
            </div>
            {/*<div className={"flex items-center"}>*/}
            {/*  <ReactSVG src="/images/icons/warning.svg" className={"mr-2"}/>*/}
            {/*  <p className={""}>ეს ფუნქციონალი მალე ჩაეშვება</p>*/}
            {/*</div>*/}
          </div>
        </Modal>
      </>
  );
});
