import {Save} from "Components/Buttons/Save";
import {CustomCheckbox} from "Components/Checkbox/Checkbox";
import {WrongInfo} from "Components/Messages/Warnings/WrongInfo";
import {CongratsModal} from "Components/Modals/Congrats";
import {CreateMember} from "Components/Modals/CreateMember";
import {DeleteModal} from "Components/Modals/Delete";
import {PaymentsModal} from "Components/Modals/Payments";
import {MembersModal} from "Components/Modals/User/Members";
import {OfferBackButton} from "Components/Offers/Layouts/OfferBackButton";
import {BankCards} from "Components/PageSections/Checkout/BankCards";
import {SectionHeader} from "Components/PageSections/Checkout/Layouts/SectionHeader";
import {Adult} from "Components/Sections/Adult";
import {Children} from "Components/Sections/Children";
import {SwitchMemberType} from "Components/Switch/SwitchMemberType";
import {handleCardRequest} from "Functions/Checkout/handleCardRequest";
import {useCardInfoQuery} from "Hooks/Queries/User/useCardInfoQuery";
import {useHasUserCard} from "Hooks/Queries/User/useHasUserCard";
import {useCardPriceQuery} from "Hooks/Queries/useCardPriceQuery";
import useWindowSize from "Hooks/useWindowSize";
import {useGetUserQuery} from "Reducers/ApiSlice";
import {handleMember} from "Reducers/FamilyMembers";
import {useAppDispatch, useAppSelector} from "Store/store";
import dayjs from "dayjs";
import {useCallback, useEffect, useRef, useState} from "react";
import {ReactSVG} from "react-svg";
import {ToastContainer, toast} from "react-toastify";
import {BalanceModal} from "../../Components/Modals/BalanceModal";
import {WithBalance} from "../../Components/PageSections/Checkout/WithBalance";
import {useUserBalance} from "../../Hooks/Queries/User/useUserBalance";
import {Promo} from "../../Components/Promo/Promo";

export const CreateMemberPage = () => {
  const dispatch = useAppDispatch();
  const {error} = useGetUserQuery();
  const member = useAppSelector((state) => state.familymembers);
  const {width} = useWindowSize();
  const paymentRef = useRef<HTMLDivElement | null>(null);
  const [promoValue, setPromoValue] = useState("");
  const members = useAppSelector((state) => state.familymembers);
  const {userHasCard, isLoadingUserHasCard} = useHasUserCard(true); // always authorized if user reachs this page
  const onlyLocalMembers = members?.members?.filter(
      (e: any) => e.isAproved != true
  );
  const [paymentMethod, setPaymentMethod] = useState<any>(undefined);
  const [isAdult, setIsAdult] = useState(true);
  const [modalStates, setModalStates] = useState({
    createMember: false,
    deleteDialog: false,
    congratsModal: false,
    balance: false,
    memberId: null,
    mobile: {
      familymembers: false,
      transactions: false,
      payments: false,
      details: false,
    },
  });
  const [isOpenMembersInPrice, setIsOpenMembersInPrice] = useState(false);
  const [membersLength, setMembersLength] = useState({
    isRedyToSent: false,
    length: 0,
  });
  const {cardPrice} = useCardPriceQuery(
      membersLength.isRedyToSent,
      membersLength.length,
      promoValue
  );
  const {cardInfo} = useCardInfoQuery(true);
  const [isOpenAddMember, setIsOpenAddMember] = useState(true);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    rememberCard: false,
    agreeToTerms: false,
    subscriptionEnabled: false,
    errors: {
      rememberCard: false,
      agreeToTerms: false,
      subscriptionEnabled: false,
    },
  });
  const {userBalance} = useUserBalance(true);

  const hasEnoughBalance = (userBalance?.amount ?? 0) >= cardPrice?.currentPayAmount;


  const handleCheckboxes = (key: string, value: boolean) => {
    setCheckboxes((e) => ({
      ...e,
      [key]: value,
    }));
  };

  const handleErrors = (key: string, value: boolean) => {
    setCheckboxes((e) => ({
      ...e,
      errors: {
        ...e.errors,
        [key]: value,
      },
    }));
  };

  console.log("paymentMethod", paymentMethod)

  const handleRequest = () => {

    if (
        checkboxes?.agreeToTerms == false ||
        checkboxes?.subscriptionEnabled == false
    ) {
      checkboxes?.subscriptionEnabled == false &&
      handleErrors("subscriptionEnabled", true);
      checkboxes?.agreeToTerms == false && handleErrors("agreeToTerms", true);
      if (
          paymentMethod?.method !== "savedcard" &&
          checkboxes?.rememberCard == false
      ) {
        console.log("abaaa")
        handleErrors("rememberCard", true);
        return;
      }
      return;
    }


    if (
        (paymentMethod?.method !== "savedcard" && paymentMethod?.method !== "balance") &&
        checkboxes?.rememberCard == false
    ) {
      console.log("aba1")
      handleErrors("rememberCard", true);
      return;
    }

    if (onlyLocalMembers?.length == 0) {
      toast("დაამატე ოჯახის წევრები", {
        type: "error",
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        theme: "light",
      });
      return;
    }

    if (paymentMethod.method === "balance" && !hasEnoughBalance) {
      console.log("sss")
      toast("თქვენ არ გაქვთ საკმარისი ბალანსი, გთხოვთ შეავსოთ", {
        type: "error",
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        theme: "light",
      });
      return;

    }

    if (paymentMethod === undefined) {
      if (paymentRef.current) {
        paymentRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
      toast("აირჩიე გადახდის მეთოდი", {
        type: "error",
        position: "bottom-right",
        autoClose: 50000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        theme: "light",
      });
      return;
    }
    handleCardRequest(
        members,
        checkboxes,
        setIsLoadingSave,
        toast,
        paymentMethod,
        promoValue
    );
  };

  const handleMobileModalStates = useCallback((key: string, state: boolean) => {
    setModalStates((prev) => ({
      ...prev,
      mobile: {
        ...prev.mobile,
        [key]: state,
      },
    }));
  }, []);

  const handleIsAdult = useCallback((state: boolean) => {
    setIsAdult(state);
  }, []);

  const handleModalStates = useCallback((key: string, state: boolean) => {
    setModalStates((prev) => ({...prev, [key]: state}));
  }, []);

  useEffect(() => {
    setMembersLength({
      isRedyToSent: true,
      length: members.members?.filter((e: any) => e.isAproved != true)?.length,
    });
  }, [members.members]);

  if (userHasCard == false || error?.isAuthorized === false) {
    window.location.href = "/";
  }

  return (
      <>
        <OfferBackButton/>
        <div className="container flex md:gap-0 items-start lg:flex-col relative md:px-[16px] md:max-w-full mb-[100px]">
          <ToastContainer/>
          {width <= 832 ? (
              <PaymentsModal
                  cardPrice={cardPrice}
                  handleModalStates={handleModalStates}
                  open={modalStates.mobile.payments}
                  close={() => handleMobileModalStates("payments", false)}
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
              />
          ) : null}

          <BalanceModal
              open={modalStates.balance}
              close={() => handleModalStates("balance", false)}
          />

          <CreateMember
              open={modalStates.createMember}
              close={() => handleModalStates("createMember", false)}
          />
          <CongratsModal
              open={modalStates.congratsModal}
              close={() => handleModalStates("congratsModal", false)}
          />
          <DeleteModal
              open={modalStates.deleteDialog}
              close={() => handleModalStates("deleteDialog", false)}
              text={`ნამდვილად გსურთ (${member?.memberId?.firstName} ${member?.memberId?.lastName}) ოჯახის წევრის წაშლა?`}
          />
          {width <= 832 ? (
              <MembersModal
                  open={modalStates.mobile.familymembers}
                  close={() => handleMobileModalStates("familymembers", false)}
                  handleRemoveMember={() => {
                    handleMobileModalStates("familymembers", false);
                    handleModalStates("deleteDialog", true);
                  }}
                  handleCreateMember={() => {
                    handleMobileModalStates("familymembers", false);
                    handleModalStates("createMember", true);
                  }}
              />
          ) : null}
          <div className="w-full rounded-[12px] p-[44px] mr-[30px] bg-gray-30 md:bg-[#fff] md:p-[16px]">
            <div>
              {width > 832 && <SectionHeader title="ოჯახის წევრის დამატება"/>}
              {width > 832 && (
                  <div className="flex items-center gap-[6px] font-medium text-rg text-blackPrimary-60 mb-[20px]">
                    <ReactSVG src="/images/icons/info.svg"/>
                    ოჯახის წევრის დამატება ფასიანია. ერთი ადამიანის დამატების
                    ღირებულება შეადგენს 15 ლარს
                  </div>
              )}
              {onlyLocalMembers?.length > 0 && (
                  <div className="mb-[40px] mt-[40px] flex flex-col gap-4">
                    {onlyLocalMembers?.map((member: any) => (
                        <div
                            className="bg-[#fff] md:bg-gray-30 px-[44px] py-[20px] rounded-[12px] flex justify-between items-center">
                          <div className="flex flex-col gap-1">
                      <span className="font-demi text-rg text-blackPrimary-100">
                        {member?.firstName} {member?.lastName}
                      </span>
                            <span className="text-rg font-regular text-blackPrimary-60">
                        {member?.phone}
                      </span>
                          </div>
                          <ReactSVG
                              onClick={() => {
                                handleModalStates("deleteDialog", true);
                                dispatch(handleMember(member));
                              }}
                              className="cursor-pointer"
                              src="/images/icons/removeusertrash.svg"
                          />
                        </div>
                    ))}
                  </div>
              )}
              {isOpenAddMember && width > 832 && (
                  <div className="bg-[#fff] rounded-[12px] p-[44px] md:p-4 mb-[40px]">
                    <WrongInfo
                        text={`არასწორი ინფორმაციის შეყვანის შემთხვევაში, გთხოვთ დარეკოთ ქოლ ცენტში <span class="text-blue-100">0322185577</span>`}
                    />
                    <div className="max-w-[428px] mt-5">
                      <SwitchMemberType
                          variant="gray"
                          state={isAdult}
                          setState={(state: boolean) => handleIsAdult(state)}
                      />
                    </div>
                    {isAdult ? (
                        <Adult
                            type="createMember"
                            additionalevent={() => setIsOpenAddMember(false)}
                        />
                    ) : (
                        <Children
                            type="createMember"
                            additionalevent={() => setIsOpenAddMember(false)}
                        />
                    )}
                  </div>
              )}
              {!isOpenAddMember && width > 832 && (
                  <Save
                      className="max-w-[247px] mb-[40px] gap-2"
                      variant="green"
                      label="დაამატე ოჯახის წევრი"
                      icon={<ReactSVG src="/images/icons/pluswhite.svg"/>}
                      onClick={() => setIsOpenAddMember(true)}
                  />
              )}
              {width <= 832 && (
                  <Save
                      className="max-w-full w-full mb-[40px] gap-2"
                      variant="green"
                      label="დაამატე ოჯახის წევრი"
                      icon={<ReactSVG src="/images/icons/memberIcon.svg"/>}
                      onClick={() => handleMobileModalStates("familymembers", true)}
                  />
              )}
            </div>
            {width > 832 ? (
                <>
                  <BankCards
                      paymentRef={paymentRef}
                      paymentMethod={paymentMethod}
                      setPaymentMethod={setPaymentMethod}
                  />
                  <div className={"mt-6"}>
                    <WithBalance
                        cardPrice={cardPrice}
                        hasEnoughBalance={hasEnoughBalance}
                        paymentMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                        handleModalStates={handleModalStates}
                    />
                  </div>

                </>
            ) : (
                <button
                    aria-label="paymentmethod"
                    onClick={() => handleMobileModalStates("payments", true)}
                    className="bg-gray-30 cursor-pointer flex justify-start items-center rounded-[12px] w-full text-rg font-bold text-blackPrimary-100 p-4 gap-2"
                >
                  <div className="flex flex-col">
                    გადახდის მეთოდები
                    {paymentMethod !== undefined &&
                        paymentMethod?.pan !== undefined && (
                            <span>{paymentMethod?.pan}</span>
                        )}
                  </div>
                  <ReactSVG
                      className="ml-auto"
                      src="/images/icons/mobilerightarrow.svg"
                  />
                </button>
            )}
          </div>
          <div
              className="sticky top-[105px] menuSize:top-[148px] md:top-0 md:relative w-[398px] lg:w-full min-w-[398px] md:min-w-full bg-gray-30 md:bg-[#fff] rounded-[12px] md:rounded-t-[0px] p-6 sm:p-[12px]">
            <h2 className="text-md font-bold text-blackPrimary-100">
              შეკვეთის დეტალები
            </h2>
            <div
                className="flex flex-col gap-[16px] pb-[20px] my-[32px] mb-[20px] border-b-[1px] border-solid border-b-[#E8ECF4]">
              <div
                  onClick={() =>
                      onlyLocalMembers?.length > 0 &&
                      setIsOpenMembersInPrice(!isOpenMembersInPrice)
                  }
                  className="flex cursor-pointer justify-between"
              >
              <span className="text-rg font-medium text-blackPrimary-100 flex items-center gap-1">
                ტრანზაქციის ღირებულება
                {onlyLocalMembers?.length > 0 && (
                    <ReactSVG
                        className={`${
                            !isOpenMembersInPrice ? "rotate-[180deg]" : "rotate-0"
                        } mt-1`}
                        wrapper="span"
                        src="/images/icons/familyArrow.svg"
                    />
                )}
              </span>

                <div className={"flex items-center"}>
                      <span className="font-demi text-[24px] text-green-100 flex items-center gap-1">
                        {cardPrice?.currentPayAmount?.toFixed(2)}{" "}
                        <ReactSVG src="/images/icons/larigreen.svg"/>
                      </span>
                  {/*{*/}
                  {/*    cardPrice?.promoCodeConditions &&*/}
                  {/*    <span className="ml-1 font-demi text-[12px] text-blackPrimary-100 flex items-center line-through">*/}
                  {/*      {cardPrice?.originalPrice?.toFixed(2)}{" "}*/}
                  {/*      /!*<ReactSVG src="/images/icons/lariSmall.svg"/>*!/*/}
                  {/*    </span>*/}
                  {/*}*/}
                </div>


              </div>
              {isOpenMembersInPrice && (
                  <div className="mt-[-12px] flex flex-col gap-3">
                    {onlyLocalMembers.map((member: any, index: number) => (
                        <div key={index} className="flex justify-between">
                          <span className="text-sm font-medium text-blackPrimary-60">
                            {member?.firstName} {member?.lastName}
                          </span>
                          <span className="text-rg text-blackPrimary-100 font-medium flex items-center gap-1">
                      {(
                          cardPrice?.currentPayAmount / onlyLocalMembers?.length
                      ).toFixed(2)}
                            <ReactSVG src="/images/icons/lariblack.svg"/>
                    </span>
                        </div>
                    ))}
                  </div>
              )}
              <div className="flex items-center justify-between">
                <span className="text-rg font-medium text-blackPrimary-100">
                  ჯამური ღირებულება
                </span>

                <div className={"flex items-center"}>
                     <span className="font-demi text-md flex items-center gap-1">
                      {cardPrice?.monthlyPayAmount}{" "}
                       <ReactSVG src="/images/icons/lariblack.svg"/>
                    </span>
                  {/*{*/}
                  {/*    cardPrice?.promoCodeConditions &&*/}
                  {/*    <span className="ml-1 font-demi text-[12px] text-blackPrimary-100 flex items-center line-through">*/}
                  {/*      {cardPrice?.originalMonthlyPayment?.toFixed(2)}{" "}*/}
                  {/*      /!*<ReactSVG src="/images/icons/lariSmall.svg"/>*!/*/}
                  {/*    </span>*/}
                  {/*}*/}
                </div>


              </div>
              <div className="flex justify-between">
              <span className="text-rg font-medium text-blackPrimary-60">
                მოქმედების ვადა
              </span>
                <span className="font-demi text-rg text-raspberry-100 flex items-center gap-1">
                {dayjs(new Date()).format("DD.MM.YYYY")} -{" "}
                  {dayjs(cardInfo?.cardEndDate).format("DD.MM.YYYY")}
                </span>
              </div>
            </div>

            {/*<div className="flex w-full mb-5 pb-5 border-b-[1px] border-solid border-b-[#E8ECF4]">*/}
            {/*  <Promo cardPrice={cardPrice} setPromoValue={setPromoValue} promoValue={promoValue}/>*/}
            {/*</div>*/}

            <div className="flex flex-col gap-4">
              <div>
                <CustomCheckbox
                    checked={checkboxes.subscriptionEnabled}
                    onChange={(e: any) => {
                      handleCheckboxes(
                          "subscriptionEnabled",
                          !checkboxes.subscriptionEnabled
                      );
                      if (e.target.checked == true)
                        handleErrors("subscriptionEnabled", false);
                    }}
                    label="გამოწერის ჩართვა"
                />
                {checkboxes.errors.subscriptionEnabled && (
                    <div className="font-demi text-raspberry-100 text-[12px]">
                      დაეთანხმეთ გამოწერას
                    </div>
                )}
              </div>
              {(paymentMethod?.method !== "savedcard" && paymentMethod?.method !== "balance") && (
                  <div>
                    <CustomCheckbox
                        checked={checkboxes.rememberCard}
                        onChange={(e: any) => {
                          handleCheckboxes("rememberCard", !checkboxes.rememberCard);
                          if (e.target.checked == true)
                            handleErrors("rememberCard", false);
                        }}
                        label="ვეთანხმები ბარათის დამახსოვრებას"
                    />
                    {checkboxes.errors.rememberCard && (
                        <div className="font-demi text-raspberry-100 text-[12px]">
                          დაეთანხმეთ ბარათის დამახსოვრებას
                        </div>
                    )}
                  </div>
              )}

              <div>
                <CustomCheckbox
                    checked={checkboxes.agreeToTerms}
                    onChange={(e: any) => {
                      handleCheckboxes("agreeToTerms", !checkboxes.agreeToTerms);
                      if (e.target.checked == true)
                        handleErrors("agreeToTerms", false);
                    }}
                    label="ვეთანხმები წესებს და პირობებს"
                />
                {checkboxes.errors.agreeToTerms && (
                    <div className="font-demi text-raspberry-100 text-[12px]">
                      დაეთანხმეთ წესებს და პირობებს
                    </div>
                )}
              </div>
              {" "}
            </div>
            <div className="z-20 mt-[32px] md:fixed md:w-full bottom-0 left-0 flex justify-center">
              <Save
                  onClick={() => !isLoadingSave && handleRequest()}
                  className="w-full md:w-[calc(100%-32px)] md:mb-3 max-w-full text-[#fff]"
                  variant="green"
                  label="გადახდა"
              />
            </div>
          </div>
        </div>
      </>
  );
};
