import { memo } from "react";
import { UserBlock } from "../../Components/PageSections/User/UserBlock/UserBlock";
import useWindowSize from "Hooks/useWindowSize";
import { ChildrenType } from "types/ReactTypes";
import { useGetUserQuery } from "Reducers/ApiSlice";
import { Helmet } from "react-helmet";

export const UserLayout = memo(function UserLayoutComponent({
  children,
}: ChildrenType) {
  const { error } = useGetUserQuery();
  const { width } = useWindowSize();

  if (error?.isAuthorized === false) {
    if (window) {
      window.location.href = "/";
    }
    return <div></div>;
  }

  if (error?.isAuthorized === undefined) {
    return <div></div>;
  }

  return (
    <div className="container flex gap-[30px] mt-[32px] mb-[100px] items-start xl:flex xl:flex-col">
      <Helmet>
        <title>მომხმარებლის კაბინეტი</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      {width > 832 && <UserBlock />}
      <div className="w-full">{children}</div>
    </div>
  );
});
