import {useCallback, useEffect, useRef, useState} from "react";
import {ReactSVG} from "react-svg";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "Store/store";
import {Link} from "react-router-dom";
import {UserLayout} from "Layouts/User/Layout";
import {fetchMembers} from "Reducers/fetchMembers";
import {CancelSubscribe} from "Components/Buttons/CancelSubscribe";
import {CongratsModal} from "Components/Modals/Congrats";
import {CreateMember} from "Components/Modals/CreateMember";
import {DeleteModal} from "Components/Modals/Delete";
import {UserSectionContainer} from "Components/PageSections/User/UserSectionContainer";
import {UserSectionHeader} from "Components/PageSections/User/UserSectionHeader";
import {FamilyMembers} from "Components/Sections/Familymembers";
import {TransactionsTable} from "Components/Tables/TransactionsTable";
import {VitaCardBlock} from "Components/VitaCardBlock/VitaCardBlock";
import {MembersModal} from "Components/Modals/User/Members";
import {TransactionsModal} from "Components/Modals/User/Transactions";
import {DetailsModal} from "Components/Modals/User/Details";
import {BasicBreadcrumbs} from "Components/Breadcrumbs/Breadcrumbs";
import {CongratsPaymentModal} from "Components/Modals/Payments/CongratsPaiment";
import {ErrorPayment} from "Components/Modals/Payments/ErrorPayment";
import {PaymentsModal} from "Components/Modals/Payments";
import {useCardInfoQuery} from "Hooks/Queries/User/useCardInfoQuery";
import {RemoveSubscription} from "Components/Modals/Payments/RemoveSubscription";
import {Save} from "Components/Buttons/Save";
import {useHasUserCard} from "Hooks/Queries/User/useHasUserCard";
import {useCustomQuery} from "Hooks/useQuery";
import useWindowSize from "Hooks/useWindowSize";
import {useCheckOrderStatusQuery} from "Hooks/Queries/User/useCheckOrderStatusQuery";
import dayjs from "dayjs";
import {useUserBankCardsQuery} from "Hooks/Queries/User/useUserBankCardsQuery";
import {SuccessSubscribe} from "Components/Modals/Payments/SuccessSubscribe";
import {ActiveSubscription} from "Components/Modals/Payments/ActiveSubscription";
import {ToastContainer} from "react-toastify";
import {Helmet} from "react-helmet";
import {Button} from "@mui/material";
import {BalanceModal} from "Components/Modals/BalanceModal";
import {useCardPriceQuery} from "../../../Hooks/Queries/useCardPriceQuery";

export const Card = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const query = useCustomQuery();
  const {order, isLoadingOrder, isSuccessOrder} = useCheckOrderStatusQuery(
      query.get("orderId"),
      true
  );
  const {isRefetchBankCard} = useUserBankCardsQuery();

  const member = useAppSelector((state) => state.familymembers);
  const {width} = useWindowSize();
  const [modalStates, setModalStates] = useState({
    createMember: false,
    deleteDialog: false,
    congratsModal: false,
    congratsPayment: false,
    congratsSubscribe: false,
    payments: false,
    errorPayment: false,
    isOpenCancelSubscription: false,
    activeSubscribe: false,
    balance: false,
    mobile: {
      familymembers: false,
      transactions: false,
      details: false,
    },
  });
  const [paymentMethod, setPaymentMethod] = useState("");
  const {userHasCard} = useHasUserCard(true); // always authorized if user reach this page
  const isActive = userHasCard;
  const {cardInfo, refetch} = useCardInfoQuery(isActive);
  const containerRef = useRef(null);

  // const {cardPrice} = useCardPriceQuery(
  //     membersLength.isRedyToSent,
  //     membersLength.length
  // );

  const handleModalStates = useCallback((key: string, state: boolean) => {
    setModalStates((prev) => ({...prev, [key]: state}));
  }, []);

  const handleMobileModalStates = useCallback((key: string, state: boolean) => {
    setModalStates((prev) => ({
      ...prev,
      mobile: {
        ...prev.mobile,
        [key]: state,
      },
    }));
  }, []);

  useEffect(() => {
    dispatch(fetchMembers());
  }, [dispatch]);

  useEffect(() => {
    if (query.get("orderId")) {
      if (isLoadingOrder == false && isSuccessOrder) {
        if (
            JSON.parse(
                JSON.parse(JSON.stringify(localStorage.getItem("orderId")))
            ) != query.get("orderId")
        ) {
          if (order?.status == "SUCCESS") {
            handleModalStates("congratsPayment", true);
            localStorage.setItem(
                "orderId",
                JSON.stringify(query.get("orderId"))
            );
          } else if (order?.status == "PAYMENT_FAILED") {
            handleModalStates("errorPayment", true);
            localStorage.setItem(
                "orderId",
                JSON.stringify(query.get("orderId"))
            );
          }
        }
      }
    }
  }, [isSuccessOrder, isLoadingOrder]);

  return (
      <UserLayout>
        <ToastContainer/>
        <div ref={containerRef}></div>
        <CreateMember
            open={modalStates.createMember}
            close={() => handleModalStates("createMember", false)}
        />
        <BalanceModal
            open={modalStates.balance}
            close={() => handleModalStates("balance", false)}
        />
        <CongratsModal
            open={modalStates.congratsModal}
            close={() => handleModalStates("congratsModal", false)}
        />
        <CongratsPaymentModal
            open={modalStates.congratsPayment}
            close={() => {
              window.location.href = "/user/card";
              handleModalStates("congratsPayment", false);
            }}
        />
        <DeleteModal
            open={modalStates.deleteDialog}
            close={() => handleModalStates("deleteDialog", false)}
            text={`ნამდვილად გსურთ (${member?.memberId?.firstName} ${member?.memberId?.lastName}) ოჯახის წევრის წაშლა?`}
        />
        <RemoveSubscription
            open={modalStates.isOpenCancelSubscription}
            close={() => handleModalStates("isOpenCancelSubscription", false)}
            text={`ნამდვილად გსურთ გამოწერის გაუქმება? (თქვენი ბარათი გაუქმდება ${dayjs(
                cardInfo?.cardEndDate
            ).format("DD.MM.YYYY")})`}
            refetch={() => {
              refetch();
              isRefetchBankCard();
            }}
        />
        <ErrorPayment
            open={modalStates.errorPayment}
            close={() => handleModalStates("errorPayment", false)}
        />
        <SuccessSubscribe
            open={modalStates.congratsSubscribe}
            close={() => handleModalStates("congratsSubscribe", false)}
        />
        <ActiveSubscription
            open={modalStates.activeSubscribe}
            close={() => handleModalStates("activeSubscribe", false)}
            handleClick={() => handleModalStates("payments", true)}
        />
        <PaymentsModal
            cardPrice={{monthlyPayAmount: 0}}
            fromProfile={true}
            open={modalStates.payments}
            close={() => handleModalStates("payments", false)}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            type="activeSub"
            refetch={() => {
              refetch();
              isRefetchBankCard();
              handleModalStates("congratsSubscribe", true);
            }}
        />
        <BasicBreadcrumbs
            breadcrumbs={[
              {
                title: "ვიტა ბარათი",
                link: "user/notifications",
              },
            ]}
        />
        {width < 832 && (
            <MembersModal
                open={modalStates.mobile.familymembers}
                close={() => handleMobileModalStates("familymembers", false)}
                handleRemoveMember={() => {
                  handleMobileModalStates("familymembers", false);
                  handleModalStates("deleteDialog", true);
                }}
                handleCreateMember={() => {
                  handleMobileModalStates("familymembers", false);
                  handleModalStates("createMember", true);
                }}
                type="profile"
            />
        )}
        {width < 832 && (
            <TransactionsModal
                open={modalStates.mobile.transactions}
                close={() => handleMobileModalStates("transactions", false)}
            />
        )}
        {width < 832 && (
            <DetailsModal
                cardInfo={cardInfo}
                open={modalStates.mobile.details}
                close={() => handleMobileModalStates("details", false)}
                onCardChange={() =>
                    cardInfo?.hasSubscription
                        ? handleModalStates("payments", true)
                        : handleModalStates("activeSubscribe", true)
                }
                onSubCancel={() => {
                  handleModalStates("isOpenCancelSubscription", true);
                  handleMobileModalStates("details", false);
                }}
            />
        )}
        {width > 832 && <UserSectionHeader title="ვიტა ბარათი"/>}
        <UserSectionContainer>
          <VitaCardBlock
              cardInfo={cardInfo}
              onClick={() => handleMobileModalStates("details", true)}
              onCardChange={() => handleModalStates("payments", true)}
              openSub={() => handleModalStates("activeSubscribe", true)}
          />
          {!isActive && width <= 832 && (
              <div className="flex flex-col gap-2 mt-4">
                <h2 className="text-md font-bold text-raspberry-100">
                  შენი ჯანმრთელობის თილისმა
                </h2>
                <span className="font-medium text-rg text-blackPrimary-60">
              მიიღე 50%-მდე ფასდაკლება 200+ კლინიკასა და აფთიაქების ქსელში
              ულიმიტოდ და მთელი საქართველოს მასშტაბით
            </span>
                <Link to={isActive ? "/createmember" : "/checkout"}>
                  <Save
                      className="sm:max-w-full sm:w-full"
                      label="ბარათის ყიდვა"
                      variant="green"
                  />
                </Link>
              </div>
          )}
          {width > 832 ? (
              <div className="mt-[22px]">
                <h2 className="mb-2 text-[18px] font-bold text-blackPrimary-100 leading-[36px]">
                  ოჯახის წევრები
                </h2>
                <FamilyMembers
                    type="profile"
                    handleRemoveMember={() => handleModalStates("deleteDialog", true)}
                    handleCreateMember={() => handleModalStates("createMember", true)}
                />
              </div>
          ) : (
              <>
                <button
                    aria-label="members"
                    onClick={() => handleMobileModalStates("familymembers", true)}
                    className="bg-[#fff] cursor-pointer flex justify-start items-center rounded-[12px] w-full mt-4 mb-4 text-rg font-bold text-blackPrimary-100 p-4 gap-2"
                >
                  ოჯახის წევრები
                  <span
                      className="bg-lightRed rounded-[50%] w-[16px] h-[16px] flex justify-center items-center text-[10px] font-demi text-[#fff]">
                {member?.members?.length}
              </span>
                  <ReactSVG
                      className="ml-auto"
                      src="/images/icons/mobilerightarrow.svg"
                  />
                </button>
              </>
          )}
          {isActive &&
              (width > 832 ? (
                  <div className="mt-[22px]">
                    <div className="flex justify-between items-center mb-[10px]">
                      <h2 className="mb-2 text-[18px] font-bold text-blackPrimary-100 leading-[36px]">
                        ტრანზაქციები
                      </h2>
                      <Button
                          onClick={() => handleModalStates("balance", true)}
                          className="bg-green-100 border-none outline-none h-[36px] px-[12px] text-sm text-[#fff] flex items-center font-bold leading-[18px] gap-1"
                      >
                        <img src="/images/icons/balancecard.svg"/>
                        ბალანსის შევსება
                      </Button>
                    </div>
                    <TransactionsTable/>
                  </div>
              ) : (
                  <>
                    <button
                        aria-label="transactions"
                        onClick={() => handleMobileModalStates("transactions", true)}
                        className="bg-[#fff] cursor-pointer flex justify-start items-center rounded-[12px] w-full mt-4 mb-4 text-rg font-bold text-blackPrimary-100 p-4 gap-2"
                    >
                      ტრანზაქციები
                      <ReactSVG
                          className="ml-auto"
                          src="/images/icons/mobilerightarrow.svg"
                      />
                    </button>
                    <button
                        aria-label="transactions"
                        onClick={() => handleModalStates("balance", true)}
                        className="bg-[#fff] cursor-pointer flex justify-start items-center rounded-[12px] w-full mt-4 mb-4 text-rg font-bold text-blackPrimary-100 p-4 gap-2"
                    >
                      ბალანსის შევსება
                      <ReactSVG
                          className="ml-auto"
                          src="/images/icons/mobilerightarrow.svg"
                      />
                    </button>
                  </>
              ))}
          {isActive &&
              cardInfo?.cardType === "INDIVIDUAL" &&
              cardInfo?.hasSubscription === true && (
                  <div className="mt-[32px] md:hidden">
                    <CancelSubscribe
                        onClick={() =>
                            handleModalStates("isOpenCancelSubscription", true)
                        }
                    />
                  </div>
              )}
        </UserSectionContainer>
      </UserLayout>
  );
};
