import { API_REQUEST } from "Request/API";

export async function handlePersonalInfoRequest(
  values: any,
  callback: any,
  setIsLoading: any,
  toast: any
) {
  return await API_REQUEST(
    "PUT",
    "user/update-from-checkout",
    {
      firstName: values.firstName,
      lastName: values.lastName,
      personalId: values.personalNumber,
    },
    undefined,
    "fullresponse"
  )
    .then(() => callback())
    .catch(() => {
      setIsLoading(false);
      toast("პირადი ნომერი უკვე გამოყენებულია", {
        type: "error",
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        theme: "light",
      });
    });
}
