
interface IProps {
  className: string
  text: string
  icon?: React.ReactNode;
}

export const ClinicAlert = ({className, text, icon}: IProps) => {
  return (
      <div
          className={`sm:mx-[16px] ${className} flex items-center text-rg sm:text-[12px] font-medium rounded-[12px] sm:px-3 sm:py-4 py-5 px-5`}>
        {icon}
        <p className={"ml-2"}>{text}</p>
      </div>
  );
};
