import {useQuery} from "@tanstack/react-query";
import {API_REQUEST} from "Request/API";

interface IBalance {
  userId: number;
  amount: number;
  currency: string;
  type: number;
  status: number;
}

export const useUserBalance = (isAuthorized: boolean) => {

  const {
    data: userBalance,
    isError: isErrorUserBalance,
    isLoading: isLoadingUserBalance,
    isSuccess: isSuccessUserBalance,
    refetch: refetchBalance
  } = useQuery<IBalance>({
    queryKey: [`BALANCE`],
    queryFn: () => API_REQUEST("GET", `/balance`),
    retry: 10,
    enabled: isAuthorized,
    refetchOnWindowFocus: true,
    staleTime: Infinity, // Data is never considered stale
  });

  return {
    refetchBalance,
    userBalance,
    isErrorUserBalance,
    isLoadingUserBalance,
    isSuccessUserBalance,
  };
};
