import {useUserBankCardsQuery} from "Hooks/Queries/User/useUserBankCardsQuery";
import {ToastContainer, toast} from "react-toastify";
import {useBankCardCheckQuery} from "Hooks/Queries/User/useBankCardCheckQuery";
import {useCustomQuery} from "Hooks/useQuery";
import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useHasUserCard} from "../../../Hooks/Queries/User/useHasUserCard";
import {useCardInfoQuery} from "../../../Hooks/Queries/User/useCardInfoQuery";

export const OrderPage = () => {
  const {bankCard, isLoadingBankCard, isErrorBankCard, isSuccessBankCard} =
      useUserBankCardsQuery();
  const query = useCustomQuery();
  const navigate = useNavigate();

  const {bankCardCheck, isLoadingBankCardCheck, isSuccessBankCardCheck} =
      useBankCardCheckQuery(query.get("orderId"));


  const {userHasCard,refetchHasUserCard} = useHasUserCard(true); // always authorized if user reach this page
  const isActive = userHasCard;
  const {cardInfo, refetch} = useCardInfoQuery(isActive);

  console.log("bankCardCheck", bankCardCheck);

  const [isOpenSuccess, setIsOpenSuccess] = useState(true);
  const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);

  useEffect(() => {
    console.log("bankCardCheck", bankCardCheck)
    if (query.get("orderId")) {
      if (isLoadingBankCardCheck == false && isSuccessBankCardCheck) {
        if (
            JSON.parse(
                JSON.parse(JSON.stringify(localStorage.getItem("bankCardOrderId")))
            ) != query.get("orderId")
        ) {
          refetch();
          refetchHasUserCard();
          if (bankCardCheck?.status == "SUCCESS") {
            navigate("/payment/success")
            console.log("success")

            localStorage.setItem(
                "bankCardOrderId",
                JSON.stringify(query.get("orderId"))
            );
          } else if (bankCardCheck?.status == "PAYMENT_FAILED") {
            // setIsOpenErrorMessage(true);
            console.log("failure")
            navigate("/payment/failure")
            localStorage.setItem(
                "orderId",
                JSON.stringify(query.get("bankCardOrderId"))
            );
          }
        }
      }
    }
  }, [isSuccessBankCardCheck, isLoadingBankCardCheck, bankCardCheck]);

  return (
      <>
        <ToastContainer/>
        <div
            className={"container max-w-[1392px] xl:max-w-[calc(100%-80px)] xl:w-[100%] md:px-[16px] md:max-w-full"}>

          <div
              className="w-full mt-[97px] mb-5 h-full flex items-center justify-center">
            <div
                className="relative max-w-[492px] outline-0 bg-[#fff] rounded-[12px] w-full flex flex-col items-center py-[30px] gap-7 px-[42px]">
              <img
                  className="w-[100%] object-cover"
                  src="/images/loader.gif"
                  alt="loader"
              />

            </div>
          </div>

        </div>
      </>
  );
};
