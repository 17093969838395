import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import useWindowSize from "Hooks/useWindowSize";
import { useArrayQueries, useCustomQuery, useSlugs } from "Hooks/useQuery";
import { ClinicCard } from "Components/Cards/ClinicCard";
import { SelectInputComponent } from "Components/Inputs/SelectInput";
import { FilterModal } from "Components/Modals/Clinics/FilterModal";
import { Filter } from "Components/PageSections/Clinics/Filter/Filter";
import { Menu } from "Components/PageSections/Clinics/Menu/Menu";
import { CustomPagination } from "Components/Pagination/CustomPagination";
import { ClinicSkeleton } from "Components/PageSections/Clinics/Skeletons/ClinicSkeleton";
import useNavigationWithQueryParams from "Hooks/useNavigationWithQueryParams";
import { RootState, useAppSelector } from "Store/store";
import { useClinicsQuery } from "Hooks/Queries/useClinicsQuery";
import { useRegionsQuery } from "Hooks/Queries/useRegionsQuery";
import { useSubCategoriesQuery } from "Hooks/Queries/useSubCategoriesQuery";
import { useMunicipalitiesQuery } from "Hooks/Queries/useMunicipalitiesQuery";
import { SearchClinic } from "Components/Search/SearchClinic";
import { BasicBreadcrumbs } from "Components/Breadcrumbs/Breadcrumbs";
import { Helmet } from "react-helmet";

export const Clinics = () => {
  let location = useLocation();
  const { width } = useWindowSize();
  const { moveToPage, handleSort } = useNavigationWithQueryParams();
  const categories = useAppSelector((state: RootState) => state.categories);
  // initialize slugs
  const slugs = useSlugs();
  const slug = slugs[slugs.length - 1];
  // get querys
  const query = useCustomQuery();
  let pageParam = query.get("page") || 1;
  // get Regions and Municipalities
  const customQuery = useArrayQueries();
  const regionIds = customQuery.regionId;
  const municipalityIds = customQuery.municipalityId;

  // page props for detect if slug is ready to sent api request.
  const [pageProps, setPageProps] = useState({
    isSlugReady: false,
  });
  // clinics page props, pagination, filter and sort
  const [clinicProps, setClinicProps] = useState({
    page: 1,
    size: 12,
    slug: "",
    regions: [],
    municipalities: [],
  });
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  /** query hooks for fetching data */

  const { data, isError, isLoading, isSuccess } = useClinicsQuery(
    clinicProps,
    slug,
    pageProps,
    regionIds,
    municipalityIds
  );


  const { childrenCategories, childrenIsError, childrenIsLoading } =
    useSubCategoriesQuery(slugs, slug);
  const { regions, isErrorRegions, isLoadingRegions, isSuccessRegions } =
    useRegionsQuery(slug, pageProps);
  const {
    municipalities,
    isErrorMunicipalities,
    isLoadingMunicipalities,
    isSuccessMunicipalities,
  } = useMunicipalitiesQuery(clinicProps.regions, slug);
  /** query hooks for fetching data */

  /** functions */
  const moveToPageCallback = useCallback((page: any) => {
    moveToPage(page);
    setClinicProps((props) => ({ ...props, page: page }));
  }, []);

  const handleSortCallback = useCallback((state: any) => {
    handleSort(state);
    setClinicProps((props) => ({ ...props, sort: state }));
  }, []);

  const handleRegionsCallback = useCallback((regionId: any) => {
    setClinicProps((prevProps: any) => {
      const newRegions = prevProps.regions.includes(regionId)
        ? prevProps.regions.filter((id: any) => id !== regionId)
        : [...prevProps.regions, regionId];

      return {
        ...prevProps,
        regions: newRegions,
        municipalities: [],
      };
    });
  }, []);

  const handleMunicipalitiessCallback = useCallback((regionId: any) => {
    setClinicProps((prevProps: any) => {
      const newMunicipalities = prevProps.municipalities.includes(regionId)
        ? prevProps.municipalities.filter((id: any) => id !== regionId)
        : [...prevProps.municipalities, regionId];

      return {
        ...prevProps,
        municipalities: newMunicipalities,
      };
    });
  }, []);
  /** functions */

  /** Window onload effects */
  useEffect(() => {
    if (slug) setPageProps((prev: any) => ({ ...prev, isSlugReady: true }));
    setClinicProps((prev: any) => ({
      ...prev,
      page: parseInt(pageParam.toString()),
      slug: slug,
      discount: query.get("sort"),
    }));
  }, [location]);

  useEffect(() => {
    const regions = Array.isArray(regionIds)
      ? regionIds
      : regionIds
      ? [regionIds]
      : [];
    setClinicProps((prev: any) => ({
      ...prev,
      regions: regions,
    }));
  }, [regionIds]);

  useEffect(() => {
    const municipalities = Array.isArray(municipalityIds)
      ? municipalityIds
      : municipalityIds
      ? [municipalityIds]
      : [];
    setClinicProps((prev: any) => ({
      ...prev,
      municipalities: municipalities,
    }));
  }, [municipalityIds]);
  /** Window onload effects */

  const filter = () => (
    <Filter
      regions={{
        regions: regions,
        isErrorRegions: isErrorRegions,
        isLoadingRegions: isLoadingRegions,
        isSuccessRegions: isSuccessRegions,
      }}
      municipalities={{
        municipalities,
        isErrorMunicipalities,
        isLoadingMunicipalities,
        isSuccessMunicipalities,
      }}
      selectedRegions={clinicProps.regions}
      selectedMunicipalities={clinicProps.municipalities}
      handleRegions={handleRegionsCallback}
      handleMunicipalities={handleMunicipalitiessCallback}
      categories={childrenCategories || categories?.list}
      category={
        slugs.length == 2 ? slugs[slugs.length - 1] : slugs[slugs.length - 2]
      }
      close={() => setIsOpenFilter(false)}
    />
  );

  return (
    <div className="container">
      <Helmet>
        <title>
          {childrenCategories?.filter((e: any) => e.slug == slug)?.[0]?.title
            ? "VITA-ს ფასდაკლება"
            : "VITA-ს კლინიკები"}{" "}
          |{" "}
          {categories?.list?.filter((e: any) => e.slug == slug)?.[0]?.title ||
            childrenCategories?.filter((e: any) => e.slug == slug)?.[0]
              ?.title ||
            " სამედიცინო მიმართულებები"}
        </title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content={
            (categories?.list?.filter((e: any) => e.slug == slug)?.[0]?.title &&
              `${
                categories?.list?.filter((e: any) => e.slug == slug)?.[0]?.title
              }-ამ გვერდზე თქვენ იხილავთ მოცემული კატეგორიის ყველა კლინიკას,  რომელშიც შეგიძლიათ მიიღოთ 50 % მდე ფასდაკლება VITA-ს ბარათის გამოყენებით.`) ||
            (childrenCategories?.filter((e: any) => e.slug == slug)?.[0]
              ?.title &&
              `${
                childrenCategories?.filter((e: any) => e.slug == slug)?.[0]
                  ?.title
              } - ამ გვერდზე თქვენ იხილავთ მოცემული კატეგორიის ყველა კლინიკას,  რომელშიც შეგიძლიათ მიიღოთ 50 % მდე ფასდაკლება VITA-ს ბარათის გამოყენებით.`) ||
            "იხილეთ კლინიკებისა და სამედიცინო მიმართულებების ჩამონათვალი, რომელთა მომსახურებაზეც შეგიძლია მიიღოთ 50 % მდე ფასდაკლება VITA-ს ბარათის გამოყენებით."
          }
        />
      </Helmet>
      {width < 832 && (
        <FilterModal
          open={isOpenFilter}
          close={() => setIsOpenFilter(false)}
          filter={filter()}
          handleSortCallback={handleSortCallback}
        />
      )}
      <Menu />
      {width < 832 && (
        <div className="flex flex-col gap-4">
          {/* <SearchClinic /> */}
          <BasicBreadcrumbs
            breadcrumbs={[
              {
                title: `${
                  categories?.list?.filter((e: any) => e.slug == slug)?.[0]
                    ?.title ||
                  childrenCategories?.filter((e: any) => e.slug == slug)?.[0]
                    ?.title ||
                  "კლინიკები"
                }`,
                link: `${slug}`,
              },
            ]}
          />
        </div>
      )}
      <div className="flex sm:mt-[17px] items-start gap-[30px] pb-12">
        <div className="md:hidden">{filter()}</div>
        <div className="w-full">
          <div className="cls flex w-full items-center justify-between mb-[24px]">
            <h1 className="font-bold text-[24px] md:text-md md:font-demi">
              {slugs[slugs.length - 1] == "aptiakebi" ||
              slugs[slugs.length - 2] == "aptiakebi"
                ? "აფთიაქები"
                : categories?.list?.filter((e: any) => e.slug == slug)?.[0]
                    ?.title ||
                  childrenCategories?.filter((e: any) => e.slug == slug)?.[0]
                    ?.title ||
                  "კლინიკები"}
            </h1>
            <button
              onClick={() => setIsOpenFilter(true)}
              className="hidden md:flex items-center gap-2 text-rg text-blackPrimary-100 font-medium"
            >
              <ReactSVG src="/images/icons/filterbtn.svg" />
              ფილტრი
            </button>
            <SelectInputComponent
              value={query.get("sort") || ""}
              className="bg-gray-30 w-[282px] md:hidden font-bold"
              label="სორტირება"
              onChange={handleSortCallback}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              options={[
                {
                  value: "discount:desc",
                  title: "მაღალი პროცენტი",
                },
                {
                  value: "discount:asc",
                  title: "დაბალი პროცენტი",
                },
              ]}
            />
          </div>
          <div className="grid grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-x-[30px] gap-y-[30px]">
            {isLoading || isError
              ? Array.from({ length: 8 }).map((_, i) => (
                  <ClinicSkeleton key={i} />
                ))
              : data?.content?.map((clinic: any, i: number) => (
                  <ClinicCard clinic={clinic} key={i} />
                ))}
          </div>
          <div className="flex justify-center my-[32px]">
            <CustomPagination
              page={clinicProps.page}
              moveToPage={moveToPageCallback}
              totalPages={data?.totalPages}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
