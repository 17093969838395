import { ClinicCard } from "Components/Cards/ClinicCard";
import { useBranchesQuery } from "Hooks/Queries/useBranchesQuery";
import { useSlugs } from "Hooks/useQuery";
import { memo } from "react";
import { ClinicSkeleton } from "../Skeletons/ClinicSkeleton";
import useWindowSize from "Hooks/useWindowSize";

export const BranchesSection = memo(function BranchesSectionComponent() {
  const slugs = useSlugs();
  const slug = slugs[slugs.length - 1];
  const {width} = useWindowSize();

  const { branches, isErrorBranches, isLoadingBranches, isSuccessBranches } =
    useBranchesQuery(0, 1000, slug);


  // /api/vita/clinic/child-clinics

  return (
    <div
      className={`${
        branches?.length == 0 && width > 832
          ? "grid-cols-1"
          : "grid-cols-4"
      } mt-[30px] sm:mt-0 mb-[60px] grid md:grid-cols-1 py-[24px] gap-7 sm:px-[16px]`}
    >
      {!isLoadingBranches && !isErrorBranches ? (
        branches?.length !== 0 ? (
          branches?.map((clinic: any, i: number) => (
            <ClinicCard clinic={clinic} key={i} />
          ))
        ) : (
          <div className="relative w-full mt-[120px] mb-[100px]">
            <div className="absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]">
              <img src="/images/nonbranches.png" />
            </div>
            <div className="relative z-10 w-full flex flex-col gap-3 items-center justify-center">
              {/* <img src="/images/icons/bigfrown.svg" /> */}
              <h3 className="font-bold text-md text-blackPrimary-100">
                ფილიალი არ მოიძებნა
              </h3>
            </div>
          </div>
        )
      ) : (
        Array.from({ length: 8 }).map((_, i) => <ClinicSkeleton key={i} />)
      )}
    </div>
  );
});
