import {AddPaymentMethod} from "Components/Buttons/AddPaymentButton";
import {BankCard} from "Components/Cards/BankCard";
import {UserLayout} from "Layouts/User/Layout";
import {UserSectionContainer} from "Components/PageSections/User/UserSectionContainer";
import {UserSectionHeader} from "Components/PageSections/User/UserSectionHeader";
import {ReactSVG} from "react-svg";
import {BasicBreadcrumbs} from "Components/Breadcrumbs/Breadcrumbs";
import {useUserBankCardsQuery} from "Hooks/Queries/User/useUserBankCardsQuery";
import {API_REQUEST} from "Request/API";
import {ToastContainer, toast} from "react-toastify";
import {handleAddCardRequest} from "Functions/Checkout/handleAddCardRequest";
import {useBankCardCheckQuery} from "Hooks/Queries/User/useBankCardCheckQuery";
import {useCustomQuery} from "Hooks/useQuery";
import {useEffect, useState} from "react";
import {SavedCardPayment} from "Components/Modals/Payments/SaveCard";
import {ErrorPayment} from "Components/Modals/Payments/ErrorPayment";
import {Helmet} from "react-helmet";
import {Save} from "../../../Components/Buttons/Save";
import {Button} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";

export const FailurePage = () => {
  const {bankCard, isLoadingBankCard, isErrorBankCard, isSuccessBankCard} =
      useUserBankCardsQuery();
  const navigate = useNavigate();


  return (
      <>
        <ToastContainer/>
        <div
            className={"container max-w-[1392px] xl:max-w-[calc(100%-80px)] xl:w-[100%] md:px-[16px] md:max-w-full"}>

          <div
              className="w-full mt-[97px] mb-5 h-full flex items-center justify-center">
            <div
                className="relative max-w-[492px] outline-0 bg-[#fff] rounded-[12px] w-full flex flex-col items-center py-[30px] gap-7 px-[42px] sm:px-3">
              <ReactSVG src="/images/icons/paymenttrash.svg"/>
              <span className="relative z-20 text-md font-medium sm:text-center text-start text-blackPrimary-100">
                სამწუხაროდ გადახდა ვერ მოხერხდა
              </span>

              <span className="relative z-20 text-md font-medium text-blackPrimary-100 sm:text-center text-start  flex sm:flex-col flex-row">
                   ინფორმაციული მხარდაჭერა:
                   <Link
                       className="flex ml-1 sm:justify-center justify-start items-center sm:text-center text-start"
                       to="tel:0322185577">
                     032 218 5577
                  </Link>
              </span>

              <Button
                  onClick={() => navigate('/')}
                  type={"button"}
                  aria-label="default"
                  // onClick={onClick}
                  className={`text-nowrap text-blackPrimary-60 bg-gray-100 whitespace-nowrap shadow-none px-[43px] py-[16px] font-bold text-rg leading-[21px] rounded-[12px]`}
                  variant="contained"
                  // disabled={disabled}
              >
                მთავარზე დაბრუნება
              </Button>
            </div>
          </div>

        </div>
      </>
  );
};
