import {useQuery} from "@tanstack/react-query";
import {API_REQUEST} from "Request/API";

export const useHasUserCard = (isAuthorized: boolean) => {
  const {
    data: userHasCard,
    isError: isErrorUserHasCard,
    isLoading: isLoadingUserHasCard,
    isSuccess: isSuccessUserHasCard,
    refetch
  } = useQuery({
    queryKey: [`USER-HAS-CARD`],
    queryFn: () => API_REQUEST("GET", `user/has-user-card`),
    retry: 0,
    enabled: isAuthorized == true,
    refetchOnWindowFocus: false,
    staleTime: Infinity, // Data is never considered stale
  });

  return {
    refetchHasUserCard: refetch,
    userHasCard,
    isErrorUserHasCard,
    isLoadingUserHasCard,
    isSuccessUserHasCard,
  };
};
