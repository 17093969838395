import {useQuery} from "@tanstack/react-query";
import {API_REQUEST} from "Request/API";

export const useBankCardCheckQuery = (id: any) => {
  const {
    data: bankCardCheck,
    isError: isErrorBankCardCheck,
    isLoading: isLoadingBankCardCheck,
    isSuccess: isSuccessBankCardCheck,
  } = useQuery({
    queryKey: [`USER_CARD_check-${id}`],
    queryFn: () => API_REQUEST("GET", `medical/bank/check-status/${id}`),
    // retry: 0,
    refetchOnWindowFocus: false,
    refetchInterval: 3000,
    enabled: id !== null,
  });

  return {
    bankCardCheck,
    isErrorBankCardCheck,
    isLoadingBankCardCheck,
    isSuccessBankCardCheck,
  };
};
