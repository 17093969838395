import {useRef} from "react";
// import { ClinicPhoneWithSkeleton } from "../Content/Clinic/Tools/ClinicPhone";
import {Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import {useCategoriesPlacement} from "Hooks/useCategoriesPlacement";
import {Location} from "Components/Location/Location";
import useWindowSize from "Hooks/useWindowSize";
import {useSlugs} from "Hooks/useQuery";
import {stateForPharmacy} from "Helpers/stateForPharmacy";

const Address = ({address}: any) => (
    <div className="md:flex md:justify-between md:items-center">
      <div className="flex flex-col gap-2 sm:w-[65%]">
        <Location
            address={address}
            // clinic={clinic}
            // isLoadingClinic={isLoadingClinic}
            // isErrorClinic={isErrorClinic}
            color="rgba(56, 56, 56, 0.60)"
        />
      </div>
      <button
          aria-label="location"
          className="mt-3 md:mt-0 text-[#fff] flex items-center text-[10px] font-demi bg-green-100 rounded-[16px] pt-[3px] pb-[5px] px-[8px]"
      >
        {address?.region}
      </button>
    </div>
);

export const ClinicCard = ({
                             clinic,
                             isOffer = false,
                           }: {
  clinic?: any;
  isOffer?: boolean;
}) => {
  const {name, address, discount, imgUrl, clinicCategory} = clinic;

  console.log("clinic",clinic)
  const slug = useSlugs();
  const {width} = useWindowSize();
  const blockref = useRef<HTMLDivElement | null>(null);
  /* eslint-disable react-hooks/rules-of-hooks */
  const itemRefs = Array.from({length: 10}, () =>
      useRef<HTMLDivElement | null>(null)
  );
  const sortLess = clinicCategory?.sort(
      (a: any, b: any) => a?.title?.length - b?.title?.length
  );
  /* eslint-enable react-hooks/rules-of-hooks */
  const {numOfCats} = useCategoriesPlacement(sortLess, blockref, itemRefs);
  const {stateForParmacyCards} = stateForPharmacy(slug);

  return (
      <Link
          to={isOffer ? "#" : `/detailed/${clinic?.id}`}
          className="md:border-b-[1px] md:border-solid md:border-b-gray-100 md:pb-[24px]"
      >
        <div
            className="flex flex-col md:flex-row h-full md:h-auto transition-transform md:gap-2 duration-[.5s] cursor-pointer md:overflow-hidden md:flex relative">
          <div
              className="bg-raspberry-100 py-[8px] px-[12px] rounded-[12px] absolute flex justify-center z-[9] right-5 md:right-[inherit] md:top-1 md:left-1 top-5">
            <span className="text-[#fff] text-[14px] md:text-[12px] font-bold">
              - {discount}%
            </span>
          </div>
          <div
              className="md:w-[104px] md:min-w-[104px] md:h-[94px] h-[172px] min-h-[172px] md:min-h-[auto] p-[12px] bg-gray-30 rounded-[16px]">
            <div className="relative overflow-hidden flex justify-center items-center h-full">
              <img
                  className="object-contain h-full"
                  src={imgUrl || "/"}
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  alt="clinic image"
              />
            </div>
          </div>
          <div className="py-3 flex flex-col h-full md:p-0 md:pl-0">
            {!isOffer && (
                <div
                    ref={blockref}
                    style={{visibility: numOfCats == null ? "hidden" : "visible"}}
                    className="flex gap-[8px] mb-2"
                >
                  {sortLess
                      ?.filter((z: any, i: number) => i < numOfCats)
                      ?.map((item: any, index: number) => (
                          <div
                              key={index}
                              className="bg-blue-10 p-2 text-blue-100 font-demi text-[10px] whitespace-nowrap w-auto rounded-[16px]"
                              ref={itemRefs[index]}
                          >
                            {item?.title}
                          </div>
                      ))}
                  {clinicCategory?.length > numOfCats && width > 832 && (
                      <Tooltip
                          title={
                            <ul className="flex flex-col gap-1">
                              {sortLess?.map(
                                  (e: any, i: number) =>
                                      i >= numOfCats && (
                                          <li key={i} className="font-medium">
                                            {e?.title}
                                          </li>
                                      )
                              )}
                            </ul>
                          }
                          placement="top"
                      >
                        <div
                            className="bg-blue-10 whitespace-nowrap rounded-[16px] text-blue-100 cursor-pointer px-[8px] justify-center items-center text-red font-demi text-[10px] flex min-w-[29px]">
                          + {clinicCategory?.length - numOfCats}
                        </div>
                      </Tooltip>
                  )}
                </div>
            )}
            <h3 className="md:mb-[5px] text-[14px] text-dark-default font-bold mb-[10px] line-clamp-2 md:h-auto max-h-[42px] leading-[21px]">
              {name}
            </h3>
            {!stateForParmacyCards && (
                <div className="mt-auto">
                  {!isOffer && width > 832 && <Address address={address}/>}
                </div>
            )}
          </div>
        </div>
        {!stateForParmacyCards && (
            <div className="mt-2">
              {width <= 832 && <Address address={address}/>}
            </div>
        )}
      </Link>
  );
};
