import {API_REQUEST} from "Request/API";

//medical/manage-subscription

export const handleActiveSub = async (toast: any, cardid: any, refetch: any, sourceId = 1) =>
    await API_REQUEST("POST", `medical/manage-subscription`, {
      sourceId: sourceId,
      cardId: cardid
    })
        .then((res) => {
          refetch();
        })
        .catch(() =>
            toast("მოხდა შეცდომა, თავიდან სცადეთ", {
              type: "error",
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              theme: "light",
            })
        );
