import { Box, Modal } from "@mui/material";
import { SearchClinic } from "Components/Search/SearchClinic";
import { RootState, useAppSelector } from "Store/store";
import { memo, useRef } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { BasicModalTypes } from "types/MembersModal";

const menuList = [
  {
    id: 0,
    title: "სამედიცინო დაწესებულებები",
    icon: "/images/icons/menu/aftiakebi.svg",
  },
  {
    id: 1,
    title: "მენტალური ჯანმრთელობა",
    icon: "/images/icons/menu/mentaluri.svg",
  },
  {
    id: 2,
    title: "სასწრაფო დახმარება",
    icon: "/images/icons/menu/samedicino.svg",
  },
  {
    id: 3,
    title: "სტომატოლოგია",
    icon: "/images/icons/menu/sascrafo.svg",
  },
  {
    id: 4,
    title: "სილამაზე და ესთეტიკა",
    icon: "/images/icons/menu/silamaze.svg",
  },
  {
    id: 5,
    title: "აფთიაქები",
    icon: "/images/icons/menu/stomatologia.svg",
  },
];

export const BurgerMenu = memo(function BurgerMenuComponent({
  open,
  close,
}: BasicModalTypes) {
  const containerRef = useRef(null);
  const categories = useAppSelector((state: RootState) => state.categories);

  return (
    <>
      <>
        <div ref={containerRef}></div>
        <Modal
          open={open}
          onClose={close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          container={containerRef.current}
          className="h-[100dvh] z-[9999] fixed w-full overflow-y-auto bg-[#fff] top-0 left-0"
        >
          <div className="h-[100dvh] z-40 w-full overflow-y-auto bg-[#fff]">
            <div className="flex justify-between items-center p-4 bg-[#fff]">
              <h2 className="font-bold text-md text-blackPrimary-100">
                ნავიგაცია
              </h2>
              <div
                onClick={close}
                className="cursor-pointer bg-gray-100 rounded-[8px] w-[36px] h-[36px] flex items-center justify-center"
              >
                <ReactSVG src="/images/icons/close.svg" />
              </div>
            </div>
            <div>
              {/* <SearchClinic /> */}
              <div className="grid grid-cols-2 gap-2 px-4 mt-4">
                <Link to="/medsector">
                  <button onClick={close} className="bg-blue-10 w-full h-[64px] rounded-[8px] font-demi text-rg text-left px-[16px] text-blue-100">
                    სამედიცინო სექტორისთვის
                  </button>
                </Link>
                <Link to="/corporate">
                  <button onClick={close} className="relative bg-green-10 w-full h-[64px] rounded-[8px] font-demi text-rg text-left px-[16px] text-green-100">
                    კორპორატიული{" "}
                    <span className="absolute top-1 right-[30px] text-raspberry-100 font-mzeqala">
                      ვიტა
                    </span>
                  </button>
                </Link>
              </div>
              <div className="px-[16px] mt-[16px]">
                <h2 className="font-bold text-rg text-blackPrimary-100">
                  პროვაიდერი დაწესებულებები
                </h2>
                <div className=" flex flex-col">
                  {categories?.list?.map((category: any, i: number) => (
                    <Link key={i} to={`/clinics/${category.slug}`}>
                      <div
                        onClick={close}
                        className="py-[12px] flex justify-between gap-2 items-center"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "44px",
                            height: "44px",
                            background: "#3A6CE81A",
                            borderRadius: "50%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ReactSVG src={menuList?.[i]?.icon} />
                        </Box>
                        <span className="mr-auto">{category.title}</span>
                        <ReactSVG
                          className="rotate-[-90deg]"
                          src="/images/icons/arrow.svg"
                        />
                      </div>
                    </Link>
                  ))}
                  <Link to={`/clinics/`}>
                    <div
                      onClick={close}
                      className="py-[12px] flex justify-between gap-2 items-center"
                    >
                      <span className="mr-auto text-blue-100">
                        ყველა კლინიკა
                      </span>
                      <ReactSVG
                        className="rotate-[-90deg]"
                        src="/images/icons/arrow.svg"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    </>
  );
});
