import {ReactSVG} from "react-svg";
import {useUserBankCardsQuery} from "Hooks/Queries/User/useUserBankCardsQuery";
import {ToastContainer, toast} from "react-toastify";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useHasUserCard} from "../../../Hooks/Queries/User/useHasUserCard";
import {useCardInfoQuery} from "../../../Hooks/Queries/User/useCardInfoQuery";

export const SuccessPage = () => {
  const {bankCard, isLoadingBankCard, isErrorBankCard, isSuccessBankCard} =
      useUserBankCardsQuery();
  const navigate = useNavigate();

  const {userHasCard,refetchHasUserCard} = useHasUserCard(true); // always authorized if user reach this page
  const isActive = userHasCard;
  const {cardInfo, refetch} = useCardInfoQuery(isActive);

  return (
      <>
        <ToastContainer/>
        <div
            className={"container flex-1 flex items-center justify-center max-w-[1392px] xl:max-w-[calc(100%-80px)] xl:w-[100%] md:px-[16px] md:max-w-full h-max mb-[100px]"}>


          <div
              className="z-[100] mt-[97px] mb-5 relative max-w-[492px] bg-[#fff] rounded-[12px] w-full flex flex-col items-center  gap-7 px-[42px]">
            <h1 className="relative z-20 font-bold text-blackPrimary-100 text-[24px]">
              გილოცავ !
            </h1>
            <h2 className="relative z-20 font-bold text-blackPrimary-60 text-center">
              შენ წარმატებით შეიძინე ვიტა ბარათი
            </h2>
            <ReactSVG src="/images/icons/partingface.svg"/>
            {/* <span className="relative z-20 text-md font-medium text-blackPrimary-100">
                შემდეგი გადახდის თარიღი {formattedDate}
              </span> */}
            <Button
                onClick={() => navigate('/')}
                type={"button"}
                aria-label="default"
                // onClick={onClick}
                className={`text-nowrap bg-[#23AD61] whitespace-nowrap shadow-none px-[43px] py-[16px] font-bold text-rg leading-[21px] rounded-[12px]`}
                variant="contained"
                // disabled={disabled}
            >
              მთავარზე დაბრუნება
            </Button>

            {/*<Save onClick={() => console.log("daxurva")} variant="green" label=""/>*/}
            <div
                className="absolute z-10 top-[50%] translate-y-[-40%] left-[50%] translate-x-[-50%] bg-modalbackground bg-contain w-[240px] h-[151px]"></div>
          </div>

        </div>
      </>
  );
};
