import {useRef} from "react";
import {Helmet} from "react-helmet";
import {AboutVita} from "Components/PageSections/Home/AboutVita/AboutVita";
import {Offers} from "Components/PageSections/Home/Offers/Offers";
import {VitaDiscount} from "Components/PageSections/Home/VitaDiscount/VitaDiscount";
import {VitaHighlit} from "Components/PageSections/Home/VitaHighlit/VitaHighlit";
import {VitaProviders} from "Components/PageSections/Home/VitaProviders/VitaProviders";
import {VitaStatistics} from "Components/PageSections/Home/VitaStatistics/VitaStatistics";
import {VitaSummer} from "Components/PageSections/Home/VitaSummer/VitaSummer";
import {VitaSummerOffers} from "Components/PageSections/Home/VitaSummerOffers/VitaSummerOffers";
import {VitaUsage} from "Components/PageSections/Home/VitaUsage/VitaUsage";
import {VitaUsers} from "Components/PageSections/Home/VitaUsers/VitaUsers";
import {useOffersQuery} from "Hooks/Queries/useOffersQuery";
import {scrolltoView} from "utils/scrolltoView";
import {Link} from "react-router-dom";
import useWindowSize from "Hooks/useWindowSize";
import {useGetUserQuery} from "./Reducers/ApiSlice";
import {useHasUserCard} from "./Hooks/Queries/User/useHasUserCard";

function App() {
  const {offers} = useOffersQuery();
  const {width} = useWindowSize();
  let vitaUsageRef = useRef<any>(null);


  let lddata = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "VitaApp",
    url: "https://vitaapp.ge",
    description:
        "ვიტა-ს ბარათის გამოყენებით თქვენ შეგიძლიათ მიიღოთ  50 % მდე ფასდაკლება 60000 + ზე მეტ სამედიცინო მომსახურებაზე საქართველოს 250 მდე კლინიკაში სულ რაღაც 15 ლარად.",
    sameAs: [
      "https://www.facebook.com/Vitahealthcard",
      "https://www.instagram.com/vita_health_card/",
      "https://www.linkedin.com/company/vita-health-card/posts/?feedView=all",
      "https://www.youtube.com/@VITA-healthCard",
    ],
    logo: "https://vitaapp.ge/images/logo.svg",
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "Customer Service",
      telephone: "+0322185577",
      email: "info@vitaapp.ge",
    },
  };
  const {error} = useGetUserQuery();
  const {userHasCard} = useHasUserCard(error?.isAuthorized);

  const scrollToNextSection = () => scrolltoView(vitaUsageRef);

  // const getBlackUrl = () => {
  //   return error?.isAuthorized
  //       ? userHasCard
  //           ? "/user/card"
  //           : "/checkout"
  //       : error?.skeleton
  //           ? "/"
  //           : "/check-out";
  // }

  return (
      <>
        <Helmet>
          <title>VITA-ჯანდაცვის ბარათი | შენი ჯანმრთელობის თილისმა</title>
          <meta
              name="description"
              content="ვიტა-ს ბარათის გამოყენებით თქვენ შეგიძლიათ მიიღოთ  50 % მდე ფასდაკლება 60000 + ზე მეტ სამედიცინო მომსახურებაზე საქართველოს 250 მდე კლინიკაში სულ რაღაც 15 ლარად."
          />
          <link rel="canonical" href="https://vitaapp.ge/"/>
          <script type="application/ld+json">{JSON.stringify(lddata)}</script>
        </Helmet>
        <VitaHighlit/>

        {/*<a href={getBlackUrl()} id={"blackFriday"}>*/}
        {/*  <div className={"overflow-hidden h-full container my-[100px] lg:my-[60px]"}>*/}
        {/*    <picture*/}
        {/*        className={`w-full relative rounded-[12px]`}>*/}
        {/*      <source media="(max-width: 640px)" srcSet={"/images/blackFridayMobile.jpg"}/>*/}
        {/*      <source media="(min-width: 640px)" srcSet={"/images/blackFriday.jpg"}/>*/}
        {/*      <img*/}
        {/*          src={"/images/blackFriday.jpg"}*/}
        {/*          alt={"black friday banner"}*/}
        {/*          width={1312}*/}
        {/*          height={500}*/}
        {/*          loading="lazy"*/}
        {/*          className={`w-full h-auto rounded-[12px]`}*/}
        {/*      />*/}
        {/*    </picture>*/}
        {/*  </div>*/}
        {/*</a>*/}

        <AboutVita handleSectionClick={scrollToNextSection}/>
        <VitaStatistics/>
        <VitaUsage usageRef={vitaUsageRef}/>
        <Link to="/detailed/185" id={"banner185"}>
          <div className="cursor-pointer rounded-[12px] overflow-hidden mb-[138px] h-full container lg:mb-[60px]">
            {width > 640 && (
                <img
                    className="w-[100%] object-cover"
                    src="/images/pspgreen.png"
                    alt="psp banner"
                />
            )}
            {width <= 640 && (
                <img
                    className="w-[100%] object-cover"
                    src="/images/pspgreenmobile.png"
                    alt="psp banner"
                />
            )}
          </div>
        </Link>
        <VitaProviders/>
        {offers
            ?.sort((a: any, b: any) => a.position - b.position)
            ?.filter((e: any) => e?.offer?.showOnMainPage == true)
            .map((offer: any, index: number) => {
              switch (offer.offer.offerType) {
                case "SPECIAL":
                  return <Offers key={index} offer={offer}/>;
                case "WEEKEND":
                  return <VitaDiscount key={index} offer={offer}/>;
                case "SUMMER":
                  return <VitaSummerOffers offer={offer} key={index}/>;
                default:
                  return;
              }
            })}
        <VitaUsers/>
        <VitaSummer/>
      </>
  );
}

export default App;
