import {API_REQUEST} from "Request/API";

export const handleCardRequest = (
    members: any,
    checkboxes: any,
    setIsLoadingSave: any,
    toast: any,
    paymentMethod: any,
    promoCode?: string
) => {
  //   const modifiedMembers = members.members.map((member: any, index: number) => ({
  //     [`member-${index}`]: JSON.stringify(member),
  //   }));
  let body = {
    subscription: checkboxes.subscriptionEnabled,
    saveCard: checkboxes?.rememberCard,
    cardId: paymentMethod?.method == "savedcard" ? paymentMethod?.card : null,
    sourceId: paymentMethod?.method == "balance" ? 2 : 1,
    addUserGroupDTOS: members.members?.filter((e: any) => e.isAproved != true),
    promoCode: promoCode
  };


  console.log("body", body, paymentMethod)

  API_REQUEST(
      "POST",
      "medical/buy-medical-card",
      body,
      undefined,
      "fullresponse"
  )
      .then((response) => {
        if (
            (paymentMethod?.method == "savedcard" || paymentMethod?.method == "balance") &&
            response?.data?.redirectUrl == null
        ) {
          window.location.href = `/user/order?orderId=${response?.data?.orderId}`;
          setIsLoadingSave && setIsLoadingSave(false);
          return;
        }

        setIsLoadingSave && setIsLoadingSave(false);
        window.location.href = response?.data?.redirectUrl;
      })
      .catch(() => {
        setIsLoadingSave && setIsLoadingSave(false);
        toast("ბარათის ყიდვისას მოხდა შეცდომა", {
          type: "error",
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          theme: "light",
        });
      });
};
