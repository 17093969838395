import { memo, useEffect, useId, useMemo, useState } from "react";
import { CustomCheckbox } from "Components/Checkbox/Checkbox";
import { MobileInput } from "Components/Inputs/MobileInput";
import { TextFieldInput } from "Components/Inputs/TextField";
import { WrongInfo } from "Components/Messages/Warnings/WrongInfo";
import { useFormik } from "formik";
import { InputMessage } from "Components/Messages/InputValidations/InputMessage";
import { Save } from "Components/Buttons/Save";
import { API_REQUEST } from "Request/API";
import { debounce } from "Helpers/Debounce";
import { Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "Store/store";
import { createMember } from "Reducers/FamilyMembers";
import { isUniqueMember } from "Helpers/isUniqueMember";
import { toast } from "react-toastify";
import personalInfoSchemeForMember from "Scheme/PersonalInfoSchemeForMember";

export const Adult = memo(function AdultComponent({
  close,
  additionalevent,
  type = "default",
}: {
  close?: any;
  additionalevent?: any;
  type?: any;
}) {
  const id = useId();
  const members = useAppSelector((state) => state.familymembers);
  const dispatch = useAppDispatch();
  const [state, setState] = useState(false);
  const [phone, setPhone] = useState("");
  const [canAddAsMember, setCanAddAsMember] = useState({
    phone: {
      status: true,
      error: "",
    },
    personalId: {
      status: true,
      error: "",
    },
  });
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      personalId: "",
      foreignCitizen: false,
    },
    validationSchema: personalInfoSchemeForMember,
    onSubmit: (values) => {
      if (phone?.length == 9) {
        let newUser = { ...values, phone: phone, id: id };
        if (isUniqueMember(newUser, members?.members)) {
          close && close();
          additionalevent && additionalevent();
          dispatch(createMember(newUser));
        } else {
          toast("იგივე ტელეფონით/პირადი ნომრით წევრი უკვე დაამატეთ", {
            type: "error",
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            theme: "light",
          });
        }
      } else {
        setState(true);
      }
    },
  });

  const handleChange = (value: any) => {
    setPhone(value);
    API_REQUEST(
      "GET",
      "user/find-by-phone",
      undefined,
      {
        phone: value,
      },
      "fullresponse"
    )
      .then((res) => {
        if (res.status == 200) {
          if (res.data == "") {
            setCanAddAsMember((e) => ({
              ...e,
              phone: {
                status: true,
                error: "",
              },
            }));
          } else {
            formik.setValues({
              firstName: res?.data?.firstName,
              lastName: res?.data?.lastName,
              personalId: res?.data?.personalNumber,
              foreignCitizen: false,
            });
            setCanAddAsMember((e) => ({
              ...e,
              phone: {
                status: res?.data?.canAddAsMember,
                error: res?.data?.errorMassage,
              },
            }));
          }
        }
      })
      .catch((err) => console.log(err, "error"));
  };

  const handlePersonalId = (e: any) => {
    formik.handleChange(e);
    debouncedPersonIdChange(e);
    setCanAddAsMember((e) => ({
      ...e,
      personalId: {
        status: false,
        error: "",
      },
    }));
  };

  const debouncedHandleChange = useMemo(() => debounce(handleChange, 500), []);

  const debouncedPersonIdChange = useMemo(
    () =>
      debounce((e) => {
        API_REQUEST(
          "GET",
          "user/find-by-personalId",
          undefined,
          {
            personalId: e.target.value,
          },
          "fullresponse"
        )
          .then((res) => {
            if (res.status == 200) {
              if (res.data == "") {
                setCanAddAsMember((e) => ({
                  ...e,
                  personalId: {
                    status: true,
                    error: "",
                  },
                }));
              } else {
                setCanAddAsMember((e) => ({
                  ...e,
                  personalId: {
                    status: res?.data?.canAddAsMember,
                    error: res?.data?.errorMassage,
                  },
                }));
              }
            }
          })
          .catch((err) => console.log(err, "error"));
      }, 500),
    []
  );

  return (
    <div className="mt-[20px] flex flex-col gap-[15px]">
      <form
        onSubmit={formik.handleSubmit}
        className="mt-[20px] flex flex-col gap-[15px]"
      >
        <div className="flex gap-4">
          <div className="w-full">
            <TextFieldInput
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="bg-[#E8ECF44D!important]"
              label="სახელი"
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <InputMessage message={formik.errors.firstName} />
            ) : null}
          </div>
          <div className="w-full">
            <TextFieldInput
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="bg-[#E8ECF44D!important]"
              label="გვარი"
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <InputMessage message={formik.errors.lastName} />
            ) : null}
          </div>
        </div>
        <div className={type === "createMember" ? "flex xl:flex-col gap-4" : ""}>
          <div
            className={`${
              type === "createMember" && "w-full"
            } flex flex-col gap-1`}
          >
            <MobileInput
              value={phone}
              onBlur={() => setState(true)}
              onChange={(e: any) => {
                setCanAddAsMember((e) => ({
                  ...e,
                  phone: {
                    status: false,
                    error: "",
                  },
                }));
                debouncedHandleChange(e);
              }}
              className="bg-[#E8ECF44D!important]"
            />
            {state && phone?.length < 9 && (
              <InputMessage message={"ტელეფონის ნომერი აუცილებელია"} />
            )}
            {type === "default" && (
              <WrongInfo
                text={`არასწორი ინფორმაციის შეყვანის შემთხვევაში, ვერ ისარგებლებთ ჯანდაცვის ბარათით`}
              />
            )}
          </div>
          <div
            className={`${
              type === "createMember" && "w-full"
            } flex flex-col gap-1`}
          >
            {type === "default" && (
              <CustomCheckbox
                name="foreignCitizen"
                checked={formik.values.foreignCitizen}
                onChange={formik.handleChange}
                label="უცხო ქვეყნის მოქალაქე"
              />
            )}
            <TextFieldInput
              name="personalId"
              value={formik.values.personalId}
              onChange={handlePersonalId}
              onBlur={formik.handleBlur}
              className="bg-[#E8ECF44D!important]"
              label="პირადი ნომერი"
            />
            {formik.touched.personalId && formik.errors.personalId ? (
              <InputMessage message={formik.errors.personalId} />
            ) : null}
            {type === "createMember" && (
              <CustomCheckbox
                name="foreignCitizen"
                checked={formik.values.foreignCitizen}
                onChange={formik.handleChange}
                label="უცხო ქვეყნის მოქალაქე"
              />
            )}
          </div>
        </div>
        {type === "default" && (
          <WrongInfo
            text={`არასწორი ინფორმაციის შეყვანის შემთხვევაში, გთხოვთ დარეკოთ ქოლ ცენტში <span class="text-blue-100">0322185577</span>`}
          />
        )}

        {canAddAsMember.phone.status == false &&
          canAddAsMember.phone.error !== "" && (
            <WrongInfo text={canAddAsMember.phone.error} />
          )}

        {canAddAsMember.personalId.status == false &&
          canAddAsMember.personalId.error !== "" && (
            <WrongInfo text={"მოცემული პირადი ნომრით უკვე არსებობს მომხმარებელი"} />
          )}

        <div className="flex justify-end mt-auto sm:mt-0">
          {canAddAsMember?.personalId?.status &&
          canAddAsMember?.phone.status ? (
            <Save
              type="submit"
              className="sm:w-full sm:min-w-full"
              variant="green"
              label="დამატება"
              onClick={() => phone?.length !== 9 && setState(true)}
            />
          ) : (
            <Button
              aria-label="default"
              className={`bg-[#ddd] text-blackPrimary-100 max-w-[160px] text-nowrap whitespace-nowrap shadow-none px-[43px] py-[16px] font-bold text-rg leading-[21px] rounded-[12px]`}
              disabled
            >
              დამატება
            </Button>
          )}
        </div>
      </form>
    </div>
  );
});
