import {TextFieldInput} from "../Inputs/TextField";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_REQUEST} from "../../Request/API";
import {ReactSVG} from "react-svg";
import {toast} from "react-toastify";
import useNavigationWithQueryParams from "../../Hooks/useNavigationWithQueryParams";

interface IProps {
  setPromoValue: (value: string) => void;
  promoValue: string;
  cardPrice: any;
}

export const Promo = ({setPromoValue, promoValue, cardPrice}: IProps) => {
  const [isCorrect, setIsCorrect] = useState(false);
  const [localValue, setLocalValue] = useState("");


  // useEffect(() => {
  //   checkPromo(localValue)
  // }, []);


  const checkPromo = async (promo: string) => {
    console.log("promoValue", typeof promoValue)

    if (localValue === ""  && !promo) {
      return setIsCorrect(false);
    } else {
      API_REQUEST(
          "GET",
          "medical/check-promo-code",
          undefined,
          {
            promoCode: promo,
          },
          "fullresponse"
      )
          .then((res) => {
            if (res.status == 200) {
              console.log("rees promo", res.data)
              setPromoValue(localValue);
              setIsCorrect(true)
            }
          })
          .catch((err) => {
            type errorObj = {
              [key: number]: string
            };


            let obj: errorObj = {
              11000001: "პრომო კოდი ვადაგასულია",
              11000002: "პრომო კოდი არ არსებობს",
              11000003: "პრომო კოდი არ არსებობს",
              11000004: "პრომო კოდი არ არსებობს",
              11000005: "თქვენ ვეღარ ისარგებლებთ მოცემული პრომო კოდით",
            }

            console.log("ans--------", obj[err.response.data.errorCode])
            console.log("err", err)

            setIsCorrect(false);

            toast(obj[err?.response?.data?.errorCode] || "პრომო კოდი არ არსებობს", {
              type: "error",
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              theme: "light",
            });
          });
    }

  }

  console.log("isCorrect", isCorrect)

  const onChange = (e: any) => {
    console.log(e.target.value);
    const val = e.target.value;

    setIsCorrect(false);
    setLocalValue(val);
    // checkPromo(val)
  }


  return (
      <>
        <div className={"relative w-full"}>
          {isCorrect && <div
              className={"absolute z-[10] top-0 right-0 bottom-0 left-0 rounded-xl bg-[#FFFFFF] flex items-center justify-between"}>

            <div className={"flex items-center"}>
              <div className={"h-full items-center flex pl-4"}>
                <ReactSVG src="/images/icons/done.svg"/>
              </div>
              <div className={"flex flex-col ml-[10px]"}>
                <p className={"text-[14px] text-blackPrimary-100 font-demi"}>{localValue}</p>
                <p className={"text-blackPrimary-60 flex items-center text-[12px]"}>{Math.round(cardPrice?.promoCodeConditions?.second)}%
                  ფასდაკლება
                  <span
                      className={"ml-[7px] text-green-100 flex items-center text-[12px] font-medium"}>(-{cardPrice?.originalPrice - cardPrice?.currentPayAmount}
                    <ReactSVG
                        src="/images/icons/lari10.svg"/>)
                  </span>
                </p>
              </div>
            </div>
            <div
                onClick={() => {
                  setLocalValue("");
                  setIsCorrect(false);
                  setPromoValue("");
                }}
                className={"mr-3 cursor-pointer"}>
              <ReactSVG
                  src="/images/icons/close.svg"/>
            </div>

          </div>}

          <TextFieldInput
              name="promo"
              label="შეიყვანე პრომო კოდი"
              className="bg-[#fff] placeholdersm"
              onChange={onChange}
              value={localValue}
          />

          <p onClick={() => checkPromo(localValue)}
             className={`absolute top-2.5 leading-[30px] right-3 cursor-pointer text-[12px] font-demi ${localValue !== "" ? "text-green-100" : "text-[#070A144D] transition2 "}`}>დადასტურება</p>


          {/*<div*/}
          {/*    className={`absolute right-2 top-2 ${isCorrect ? "bg-green-100" : "bg-blackPrimary-10"} flex items-center justify-center w-[36px] h-[36px] rounded-[8px]`}>*/}

          {/*</div>*/}
        </div>
      </>
  );
};
