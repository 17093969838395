import { Save } from "Components/Buttons/Save";
import { VitaButton } from "Components/Buttons/VitaButton";
import { ActiveSubscription } from "Components/Modals/Payments/ActiveSubscription";
import { useHasUserCard } from "Hooks/Queries/User/useHasUserCard";
import { useUserBankCardsQuery } from "Hooks/Queries/User/useUserBankCardsQuery";
import useWindowSize from "Hooks/useWindowSize";
import dayjs from "dayjs";
import { memo, useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

export const BlockListItem = ({ title }: { title: string }) => (
  <h3 className="text-rg font-medium text-blackPrimary-60">{title}</h3>
);

export const VitaCardBlock = memo(function VitaCardBlockComponent({
  onClick,
  onCardChange,
  cardInfo,
  openSub,
}: any) {
  const { bankCard, isLoadingBankCard, isErrorBankCard, isSuccessBankCard } =
    useUserBankCardsQuery();
  const { width } = useWindowSize();
  const { userHasCard } = useHasUserCard(true); // always authorized if user reachs this page
  const isActive = userHasCard;
  const formattedDate = dayjs(cardInfo?.cardEndDate).format("DD.MM.YYYY");
  const formattedDateStart = dayjs(cardInfo?.cardStartDate).format(
    "DD.MM.YYYY"
  );

  // Calculate the remaining days from today
  const remainingDays = dayjs(cardInfo?.cardEndDate).diff(dayjs().subtract(1, "day"), "day");

  return (
    <div className="md:flex-col-reverse md:gap-3 md:p-[16px] md:items-center flex items-end gap-[54px] bg-[#fff] p-[32px] rounded-[12px] relative before:content-[''] before:bg-vitaBlockBg before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-no-repeat">
      {isActive && width <= 832 && (
        <button
          aria-label="carddetails"
          onClick={onClick}
          className="relative z-10 text-[12px] font-demi underline text-raspberry-100"
        >
          ბარათის დეტალები
        </button>
      )}
      <div className="min-w-[236px] relative">
        <img src="/images/vitacard.png" alt="vitacardimage" />
      </div>
      <div className="w-full relative">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-[20px] md:text-md font-bold leading-[29px] text-blackPrimary-100">
            ვიტა ბარათი
          </h2>
          {isActive ? (
            <span className="flex items-center gap-[3px] text-green-100 font-bold text-rg">
              <ReactSVG src="/images/icons/check.svg" /> აქტიურია
            </span>
          ) : (
            <span className="flex items-center gap-[3px] text-raspberry-100 font-bold text-rg">
              არააქტიური
            </span>
          )}
        </div>
        <div className="md:hidden bg-gray-30 rounded-[12px] p-[24px] py-[18px] flex flex-col gap-[24px]">
          {isActive ? (
            cardInfo?.cardType === "INDIVIDUAL" ? (
              <>
                <div className="flex justify-between">
                  <BlockListItem title={"შემდეგი გადახდა"} />
                  <span className="text-rg text-blackPrimary-100 font-demi">
                    {`${formattedDate}`}{" "}
                    <span className="text-blackPrimary-60 customxlsize:hidden">
                      (დარჩენილია {remainingDays} დღე)
                    </span>
                  </span>
                </div>
                <div className="flex justify-between">
                  <BlockListItem title={"გადასახდელი თანხა"} />
                  <span className="cursor-pointer flex items-center gap-2 text-rg text-blackPrimary-100 font-demi">
                    {cardInfo?.nextPayAmount?.toFixed(2)} ლარი
                    <ReactSVG wrapper="span" src="/images/icons/info.svg" />
                  </span>
                </div>
                <div className="flex justify-between">
                  {!cardInfo?.subscriptionByBalance && cardInfo?.hasSubscription && <div className="flex items-center gap-3">
                    <BlockListItem title={"ბარათი"}/>

                    {bankCard?.subscriptionCard ?
                        <>
                        <span
                            className="border-[1px] rounded-[6px] border-gray-100 border-solid flex items-center justify-center px-[5px] py-[4px] bg-[#fff]">
                          <img
                              className="max-w-[24px]"
                              alt={"visa&ms"}
                              src={
                                bankCard?.subscriptionCard.pan.substring(0, 1) ==
                                "5"
                                    ? "/images/icons/mastercard.svg"
                                    : "/images/icons/visacard.svg"
                              }
                          />
                        </span>
                          <span className="text-sm font-demi">
                          **
                            {bankCard?.subscriptionCard?.pan?.slice(
                                bankCard?.subscriptionCard?.pan?.length - 4,
                                bankCard?.subscriptionCard?.pan?.length
                            )}
                        </span>
                        </>
                        : <span className="text-sm font-demi">
                        ბარათი არ მოიძებნა
                      </span>
                    }

                  </div>}
                  {
                    cardInfo?.subscriptionByBalance && cardInfo?.hasSubscription && <div className="flex items-center gap-[6px]">
                        <ReactSVG  src="/images/icons/withBalance.svg" />
                        <BlockListItem title={"საიტის ბალანსით"}/>
                      </div>
                  }
                  {cardInfo?.hasSubscription ? (
                      <span
                          onClick={onCardChange}
                          className="cursor-pointer text-rg leading-[21px] text-raspberry-100 underline font-demi">
                      გადახდის მეთოდის შეცვლა
                    </span>
                  ) : (
                      <span
                          onClick={openSub}
                          className="cursor-pointer text-rg leading-[21px] text-raspberry-100 underline font-demi"
                      >
                      გამოწერის ჩართვა
                    </span>
                  )}
                </div>
              </>
            ) : cardInfo?.cardType === "CORPORATE" ? (
                <>
                  <div className="flex justify-between">
                    <BlockListItem title={"ბარათი გაააქტიურა"}/>
                    <span className="text-rg text-blackPrimary-100 font-demi">
                    {cardInfo?.medicalCorporateClientName}
                  </span>
                  </div>
                  <div className="flex justify-between">
                    <BlockListItem title={"ბარათი გააქტიურდა"}/>
                    <span className="text-rg text-blackPrimary-100 font-demi">
                    {`${formattedDateStart}`}
                  </span>
                  </div>
                  <div className="flex justify-between">
                    <BlockListItem title={"აქტიურობის ვადა იწურება"}/>
                    <span className="text-rg text-blackPrimary-100 font-demi">
                    {`${formattedDate}`}
                  </span>
                  </div>
                </>
            ) : (
                <div className="text-raspberry-100 font-demi p-[30px] flex items-center justify-center">
                  მონაცემები არ მოიძებნა
                </div>
            )
          ) : (
              <div className="flex flex-col gap-6">
                <h2 className="text-md font-bold text-raspberry-100">
                  შენი ჯანმრთელობის თილისმა
              </h2>
              <span className="font-medium text-rg text-blackPrimary-60">
                მიიღე 50%-მდე ფასდაკლება 200+ კლინიკასა და აფთიაქების ქსელში
                ულიმიტოდ და მთელი საქართველოს მასშტაბით
              </span>
              <Link to="/checkout">
                <Save label="ბარათის ყიდვა" variant="green" />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
